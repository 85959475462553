import React from "react";
import { Navigate } from "react-router-dom";


// import FileManager from "../pages/FileManager/index";

// Profile
import UserProfile from "../pages/Authentication/user-profile";
import Waitlist from "../pages/Authentication/Newsletter";


import DetailingLanding from "../pages/Detailing/DetailingLanding/index";
import BookAppointment from "../pages/Detailing/DetailingLanding/booking";
import ServicePackages from "../pages/Detailing/DetailingLanding/ServicePackages";
import FullServicePage from "../pages/Detailing/DetailingLanding/FullServicePage";
import SpecialOffers from "../pages/Detailing/DetailingLanding/SpecialOffers";
import ContactUs from "../pages/Detailing/DetailingLanding/ContactUs";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/newsletter-confirm";

import FeedbackConfirmMail from "../pages/AuthenticationInner/feedback-confirm";







// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";
import ReCharts from "../pages/Charts/ReCharts";



//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";










const authProtectedRoutes = [




  // //profile
  { path: "/profile", component: <UserProfile /> },



  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },
  { path: "/re-charts", component: <ReCharts /> },

  // Icons
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },






  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/detailing-home" />,
  },
];

const publicRoutes = [

  { path: "/detailing-home", component: <DetailingLanding /> },
  { path: "/book-appointment", component: <BookAppointment /> },
  { path: "/services", component: <ServicePackages /> },
  { path: "/full-service", component: <FullServicePage /> },
  { path: "/special-offers", component: <SpecialOffers /> },
  { path: "/contact", component: <ContactUs /> },
  { path: "/feedback-confirmation", component: <FeedbackConfirmMail /> },
  { path: "/newsletter", component: <Waitlist /> },







  // Authentication Inner
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/newsletter-confirm", component: <ConfirmMail2 /> },


];

export { authProtectedRoutes, publicRoutes };
