import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavLink, Container, NavbarToggler, Collapse } from 'reactstrap';
import logo from "assets/images/smalllogo.jpg";
import './NavBar2.css'; // Import the CSS file

const NavBar2 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className="custom-navbar" expand="md" fixed="top">
      <Container>
        <div className="mobile-container d-lg-none">
          <NavbarToggler onClick={toggle} className="mr-2" />
          <Link to="/detailing-home" className="navbar-brand mx-auto">
            <img src={logo} alt="TADL Logo" className="logo-mobile" />
          </Link>
        </div>

        <Collapse isOpen={isOpen} navbar>
          <Nav className="w-100 d-flex align-items-center justify-content-between" navbar>
            <div className="nav-left">
              <NavItem>
                <NavLink tag={Link} to="/full-service" style={{ cursor: 'pointer' }}>Services</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/special-offers" style={{ cursor: 'pointer' }}>Special Offers</NavLink>
              </NavItem>
            </div>

            <Link to="/detailing-home" className="navbar-brand mx-auto d-none d-lg-block">
              <img src={logo} alt="TADL Logo" className="logo" />
            </Link>

            <div className="nav-right">
              <NavItem>
                <NavLink tag={Link} to="/contact" style={{ cursor: 'pointer' }}>Contact / Review Us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/book-appointment" className="book-now-btn" style={{ cursor: 'pointer' }}>BOOK NOW!</NavLink>
              </NavItem>
            </div>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar2;
