// // import React from "react"
// import { cryptoOrders, wallet, productData, cryptoOrderData, icoLandingTeam, blogs, orderbookData } from "./crypto";
// import { invoiceList } from "./invoices";
// import { projects, projectListData, OverviewTeamMember } from "./projects";
// import { tasks, recentTasksData, AddTeamMember } from "./tasks";
// import { allmail, mailDB, labelsData, mailChatData } from "./mails";
// import {
//   cartData,
//   comments,
//   customerData,
//   discountData,
//   orders,
//   productsData,
//   recentProducts,
//   shops,
//   productComments,
//   productListvar
// } from "./ecommerce";
// import { chats, contacts, groups, messages } from "./chat";
// import { calenderDefaultCategories, events } from "./calender";
// import { users, userProfile } from "./contacts";
// import { yearData, monthData, weekData, latestTransaction, activityDataDashboard, TopCitiesSelling } from "./dashboard";
// import { myfiles, recentfile, filesData, filemanagerData, storageData } from "./file-manager";

// import {
//   janTopSellingData,
//   decTopSellingData,
//   novTopSellingData,
//   octTopSellingData,
//   janEarningData,
//   decEarningData,
//   novEarningData,
//   octEarningData,
//   chatData,
//   tasksData
// } from "./dashboard-saas";

// import { marchWalletData, febWalletData, janWalletData, decWalletData, transactionsDataALL, transactionsDataBuy, transactionsDataSell, notificationsData } from "./dashboard-crypto";

// import { jobVacancy, statistic_data, recentAddedJobsData, activityFeedData } from "./dashboard-job";

// import { visitor, blodStatsData, postRecentData, postPopularData, commentsData, progressData, activityData } from "./dashboard-blog";

// import { jobs, jobListCandidate, jobApply } from "./job";

// import { categoriesData, archiveData, popularPosts, tagsData } from "./blog";

// import { jobsGridData, experienceData, jobType } from "./job-Grid";

// export {
//   tagsData,
//   storageData,
//   jobType,
//   experienceData,
//   jobsGridData,
//   categoriesData,
//   archiveData,
//   popularPosts,
//   orderbookData,
//   productsData,
//   discountData,
//   events,
//   calenderDefaultCategories,
//   chats,
//   groups,
//   contacts,
//   messages,
//   orders,
//   cartData,
//   customerData,
//   shops,
//   recentProducts,
//   comments,
//   wallet,
//   productData,
//   cryptoOrderData,
//   icoLandingTeam,
//   blogs,
//   allmail,
//   cryptoOrders,
//   invoiceList,
//   projects,
//   projectListData,
//   OverviewTeamMember,
//   tasks,
//   recentTasksData,
//   AddTeamMember,
//   users,
//   userProfile,
//   yearData,
//   monthData,
//   weekData,
//   latestTransaction,
//   TopCitiesSelling,
//   activityDataDashboard,
//   janTopSellingData,
//   decTopSellingData,
//   novTopSellingData,
//   octTopSellingData,
//   janEarningData,
//   decEarningData,
//   novEarningData,
//   octEarningData,
//   marchWalletData,
//   febWalletData,
//   janWalletData,
//   decWalletData,
//   transactionsDataALL,
//   transactionsDataBuy,
//   transactionsDataSell,
//   notificationsData,
//   chatData,
//   tasksData,
//   jobVacancy,
//   productComments,
//   productListvar,
//   jobs,
//   jobListCandidate,
//   jobApply,
//   myfiles,
//   recentfile,
//   filemanagerData,
//   filesData,
//   visitor,
//   blodStatsData,
//   postRecentData,
//   postPopularData,
//   commentsData,
//   progressData,
//   activityData,
//   statistic_data,
//   recentAddedJobsData,
//   activityFeedData,
//   mailDB,
//   labelsData,
//   mailChatData
// };
