import PropTypes from 'prop-types';
import React from "react";
import { Card, CardBody, Col } from "reactstrap";

const CardBox = props => {
  const applyDiscount = (price, discount) => {
    const priceValue = parseFloat(price.replace('From $', ''));
    const discountPrice = (priceValue * (1 - discount / 100));
    return { originalPrice: `From $${priceValue}`, discountPrice: `$${discountPrice}` };
  };

  const discount = 20; // Discount percentage

  return (
    <React.Fragment>
      {props.services.map((service, key) => {
        const { originalPrice, discountPrice } = applyDiscount(service.value, discount);
        return (
          <Col md="4" key={key}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span
                      className={
                        "avatar-title rounded-circle bg-" + service.color + "-subtle text-" +
                        service.color +
                        " font-size-18"
                      }
                    >
                      <i className={service.icon} />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <p className="text bold-title">{service.title}</p>
                    <h5>
                      <span className="original-price">{originalPrice}</span>
                      <span className="discount-price">{discountPrice}</span>
                    </h5>
                    <p className="text-muted mb-0">
                      {service.description}
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </React.Fragment>
  );
};

CardBox.propTypes = {
  services: PropTypes.array
};

export default CardBox;
