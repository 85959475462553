// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.special-offers-bg {
  background: linear-gradient(to right, #f35f3a, #4ba672);
  padding-bottom: 50px;
  color: #fff;
}

.special-offers-title {
  color: #fff;
}

.service-package-card.vibrant-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.service-package-card.vibrant-card:hover {
  transform: scale(1.05);
}

.custom-table {
  margin-bottom: 15px;
}

.original-price {
  text-decoration: line-through;
  margin-right: 5px;
  color: #555;
}

.discount-price {
  color: #ff5722;
}

.text-center .btn-warning {
  background-color: #ff5722;
  border-color: #ff5722;
}

.text-center .btn-warning:hover {
  background-color: #e64a19;
  border-color: #e64a19;
}

.special-banner {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 40px;
  color: #333;
}

.special-banner h3 {
  color: #c69335;
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.special-banner p {
  font-size: 1.2rem;
  margin: 5px 0;
}

.special-banner strong {
  color: #ff5722;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Detailing/DetailingLanding/SpecialOffers.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;EACvD,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,0CAA0C;EAC1C,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,0CAA0C;EAC1C,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".special-offers-bg {\n  background: linear-gradient(to right, #f35f3a, #4ba672);\n  padding-bottom: 50px;\n  color: #fff;\n}\n\n.special-offers-title {\n  color: #fff;\n}\n\n.service-package-card.vibrant-card {\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);\n  transition: transform 0.3s;\n}\n\n.service-package-card.vibrant-card:hover {\n  transform: scale(1.05);\n}\n\n.custom-table {\n  margin-bottom: 15px;\n}\n\n.original-price {\n  text-decoration: line-through;\n  margin-right: 5px;\n  color: #555;\n}\n\n.discount-price {\n  color: #ff5722;\n}\n\n.text-center .btn-warning {\n  background-color: #ff5722;\n  border-color: #ff5722;\n}\n\n.text-center .btn-warning:hover {\n  background-color: #e64a19;\n  border-color: #e64a19;\n}\n\n.special-banner {\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);\n  padding: 20px;\n  margin-bottom: 40px;\n  color: #333;\n}\n\n.special-banner h3 {\n  color: #c69335;\n  font-size: 2.5rem;\n  margin-bottom: 10px;\n}\n\n.special-banner p {\n  font-size: 1.2rem;\n  margin: 5px 0;\n}\n\n.special-banner strong {\n  color: #ff5722;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
