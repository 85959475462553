import React from "react";
import { Container, Row, Col } from "reactstrap";

// Import Components
import FooterLink from "./footer-link";

const Footer = () => {
  const footerLinks = [
    {
      title: "Services",
      links: [
        { title: "Home Page", link: "/detailing-home" },
        { title: "View Services", link: "/full-service" },
        { title: "Book Appointment", link: "/book-appointment" },
        { title: "Special Offers", link: "/special-offers" },
      ],
    },
    {
      title: "Follow Us",
      links: [
        { title: "All Links", link: "https://linktr.ee/autodetaillab" },
        { title: "Instagram", link: "https://www.instagram.com/_autodetaillab_?igsh=YmtoNWJ3NWp0dm5h&utm_source=qr" },
        { title: "Tiktok", link: "https://twitter.com" },
      ],
    },
    {
      title: "Contact Us",
      links: [
        { title: "Text/Call us at: 647-978-6544", link: "tel:6479786544" },

        { title: "Email us: autodetaillabtoronto@gmail.com", link: "mailto:autodetaillabtoronto@gmail.com" },
        { title: "Newsletter", link: "https://theautodetaillab.com/newsletter" },

      ],
    }
  ];

  return (
    <React.Fragment>
      <footer className="landing-footer">
        <Container>
          <Row>
            {footerLinks.map((footerLink, key) => (
              <Col lg="4" sm="6" key={key}>
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((link, key) => (
                      <li key={key}>
                        <a href={link.link} 
                           target={link.link.startsWith("http") ? "_blank" : ""}
                           rel={link.link.startsWith("http") ? "noopener noreferrer" : ""}
                        >
                          {link.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>

          <hr className="footer-border my-5" />

          <Row>
            <Col md="12" className="text-center">
              <p className="copy-rights mb-0">
                © {new Date().getFullYear()} The Auto Detailing Lab. All Rights Reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
