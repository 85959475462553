import React from "react";
import { Row, Col } from "reactstrap";

//Import Images
import logolight from "../../../../assets/images/logo-light.png";

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="12" className="text-center text-lg-left">
          <div className="mb-4">
            {/* Displaying the logo */}
            {/* <img src={logolight} alt="The Auto Detailing Lab Logo" height="40" /> */}
          </div>

          <p className="mb-0">
            {new Date().getFullYear()} © The Auto Detailing Lab. All Rights Reserved.
          </p>

          {/* Optionally, you can add social media links here if needed */}
          <div className="social-links mt-3">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="me-2 text-muted">
              <i className="mdi mdi-facebook"></i>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="me-2 text-muted">
              <i className="mdi mdi-instagram"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="me-2 text-muted">
              <i className="mdi mdi-twitter"></i>
            </a>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default FooterLink;
