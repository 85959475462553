import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Modal, ModalBody } from 'reactstrap';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './SwipeableImages.css';

const InstagramFeed = ({ accessToken }) => {
  const [posts, setPosts] = useState([]);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,children{media_type,media_url,thumbnail_url}&access_token=${accessToken}`);
        const data = response.data.data;

        const processedPosts = data.map(post => {
          if (post.media_type === 'CAROUSEL_ALBUM' && post.children) {
            return { ...post, media: post.children.data };
          }
          return { ...post, media: [{ media_type: post.media_type, media_url: post.media_url, thumbnail_url: post.thumbnail_url }] };
        });

        setPosts(processedPosts);
      } catch (error) {
        console.error('Error fetching Instagram posts', error);
      }
    };

    fetchPosts();

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(3);
      }
    };

    // Set initial state
    handleResize();

    // Listen for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [accessToken]);

  const toggleModal = () => setModal(!modal);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    toggleModal();
  };

  return (
    <div className="instagram-feed text-center">
      <Swiper
        spaceBetween={10}
        slidesPerView={slidesPerView}
        allowTouchMove={false}  
        navigation={{
          nextEl: `.outer-swiper-button-next`,
          prevEl: `.outer-swiper-button-prev`
        }}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
      >
        {posts.map((post, index) => (
          <SwiperSlide key={index}>
            <div className="post-group">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                  nextEl: `.inner-swiper-button-next-${index}`,
                  prevEl: `.inner-swiper-button-prev-${index}`
                }}
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
              >
                {post.media.map((mediaItem, mediaIndex) => (
                  <SwiperSlide key={mediaIndex}>
                    <div className="post-item">
                      {mediaItem.media_type === 'IMAGE' && (
                        <img 
                          src={mediaItem.media_url} 
                          alt={post.caption || 'Instagram post'} 
                          className="fixed-size" 
                          onClick={() => handleImageClick(mediaItem.media_url)} 
                        />
                      )}
                      {mediaItem.media_type === 'VIDEO' && (
                        <video controls className="fixed-size">
                          <source src={mediaItem.media_url} type="video/mp4" />
                        </video>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
                <div className={`swiper-button-prev inner-swiper-button-prev inner-swiper-button-prev-${index}`}></div>
                <div className={`swiper-button-next inner-swiper-button-next inner-swiper-button-next-${index}`}></div>
              </Swiper>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev outer-swiper-button-prev"></div>
        <div className="swiper-button-next outer-swiper-button-next"></div>
      </Swiper>

      {/* Modal for full image view */}
      <Modal isOpen={modal} toggle={toggleModal} className="image-modal">
        <div className="close-icon" onClick={toggleModal}>
          <i className="bx bx-x"></i>
        </div>
        <ModalBody className="p-0">
          {selectedImage && (
            <img src={selectedImage} alt="Full view" className="img-fluid w-100" />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InstagramFeed;
