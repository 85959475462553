import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

// Import Components
import Accordian from "./accordian";

const FAQs = () => {
  const [activeTab, setactiveTab] = useState("1");

  return (
    <React.Fragment>
      <section className="section" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">FAQs</div>
                <h4>Frequently Asked Questions</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          setactiveTab("1");
                        }}
                      >
                        <i className="bx bx-help-circle nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">General Questions</p>
                      </NavLink>

                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          setactiveTab("2");
                        }}
                      >
                        <i className="bx bx-car nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">Services</p>
                      </NavLink>

                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          setactiveTab("3");
                        }}
                      >
                        <i className="bx bx-book-open nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">Booking</p>
                      </NavLink>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1" id="general">
                            <h4 className="card-title mb-4">General Questions</h4>

                            <Accordian
                              question1="Why is car detailing important for my health?"
                              answer1="Car detailing isn't just about aesthetics; it plays a crucial role in maintaining air quality inside your vehicle. Regular interior detailing removes harmful contaminants, allergens, and bacteria that can accumulate over time, improving the air you breathe and protecting your health."
                              question2="How does detailing help with air quality?"
                              answer2="Studies have shown that the air quality inside cars can be worse than in other environments where we spend time. Detailing services, especially deep cleaning and ozone treatments, help eliminate odors, bacteria, and other pollutants, ensuring a cleaner, healthier environment in your car."
                              question3="Do you offer mobile detailing services?"
                              answer3="Yes, we are a fully mobile detailing service, meaning we come to your location, whether it's your home, office, or anywhere else. This makes it convenient for you to keep your vehicle in top condition without disrupting your day."
                              question4="What areas do you service?"
                              answer4="We proudly serve the entire Greater Toronto Area (GTA), including Toronto, Mississauga, Brampton, Vaughan, Markham, and surrounding areas. Our mobile service ensures that wherever you are in the GTA, we can reach you."
                              question5="What are the benefits of a subscription model?"
                              answer5="Our subscription model offers regular, scheduled detailing services at a discounted rate. This ensures your vehicle stays in pristine condition year-round, with the added convenience of automatic bookings and flexible service options tailored to your needs."
                            />
                          </TabPane>

                          <TabPane tabId="2" id="services">
                            <h4 className="card-title mb-4">Services</h4>

                            <Accordian
                              question1="What services do you offer?"
                              answer1="We offer a range of services including exterior wash, wax, interior cleaning, deep cleaning, paint correction, ceramic coating, ozone treatment, and more. Each service is designed to enhance the appearance and health of your vehicle."
                              question2="What is an ozone treatment?"
                              answer2="Ozone treatment is a powerful method to eliminate odors, bacteria, and other pollutants from the interior of your vehicle. It works by using ozone gas to break down contaminants, leaving your car smelling fresh and free of harmful particles."
                              question3="Can detailing really improve the resale value of my car?"
                              answer3="Absolutely. Regular detailing helps maintain your car's appearance and protects it from wear and tear. A well-maintained vehicle with a pristine interior and exterior is much more appealing to potential buyers, which can increase its resale value."
                              question4="Are your products safe for children and pets?"
                              answer4="Yes, we use eco-friendly and non-toxic products that are safe for both children and pets. Our priority is not only to deliver exceptional results but also to ensure the safety of your family and the environment."
                            />
                          </TabPane>

                          <TabPane tabId="3" id="booking">
                            <h4 className="card-title mb-4">Booking</h4>

                            <Accordian
                              question1="How can I book a service?"
                              answer1="You can book a service through our website, by calling our service hotline, or by text. We recommend booking in advance to ensure your preferred time slot is available."
                              question2="What are your opening hours?"
                              answer2="We offer our service every day of the week between 6am - 9pm."
                              question3="Can I subscribe to regular detailing services?"
                              answer3="Yes, we offer subscription models for clients who want frequent detailing. You can choose a plan that fits your schedule, and we’ll handle the rest, ensuring your vehicle stays in perfect condition throughout the year."
                              question4="Do you offer any guarantees or warranties?"
                              answer4="Yes, we offer a satisfaction guarantee on all of our services. If you're not completely satisfied with the results, please let us know, and we'll make it right."
                            />
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default FAQs;
