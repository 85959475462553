import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const Authmiddleware = ({ children }) => {
  const location = useLocation();
  
  if (!localStorage.getItem("authUser")) {
    return <Navigate to="/detailing-home" state={{ from: location }} replace />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default Authmiddleware;
