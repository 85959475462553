// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-table-container .equal-card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .services-table-container .equal-card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .services-table-container .equal-card .card-title,
  .services-table-container .equal-card .card-text {
    flex: 0 1 auto;
  }
  
  .services-table-container .equal-card .table {
    flex: 1 1 auto;
    margin-bottom: 1rem;
  }
  
  .services-table-container .equal-card .text-center {
    flex: 0 1 auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Detailing/DetailingLanding/ServicesTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;EAChC;;EAEA;;IAEE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".services-table-container .equal-card {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .services-table-container .equal-card .card-body {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n  \n  .services-table-container .equal-card .card-title,\n  .services-table-container .equal-card .card-text {\n    flex: 0 1 auto;\n  }\n  \n  .services-table-container .equal-card .table {\n    flex: 1 1 auto;\n    margin-bottom: 1rem;\n  }\n  \n  .services-table-container .equal-card .text-center {\n    flex: 0 1 auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
