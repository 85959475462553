// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-bg {
  background: linear-gradient(to right, #4b1507, #7b4c29);
  padding-top: 35px;
  padding-bottom: 25px;

  color: #fff;
}

.contact-us-title {
  color: #fff;
}

.contact-us-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 0px;
  margin-top: 100px; /* Add this line to provide padding at the top */
}

.form-control {
  border-radius: 5px;
}

.text-end .btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.text-end .btn-success:hover {
  background-color: #218838;
  border-color: #218838;
}

.social-media-links {
  margin-top: 20px;
}

.social-media-links h5 {
  color: #333;
}

.social-link {
  display: inline-block;
  margin: 0 10px;
  color: #2e654c;
  font-size: 1.2rem;
  text-decoration: none;
}

.social-link:hover {
  color: #feb47b;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Detailing/DetailingLanding/ContactUs.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;EACvD,iBAAiB;EACjB,oBAAoB;;EAEpB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,0CAA0C;EAC1C,YAAY;EACZ,iBAAiB,EAAE,gDAAgD;AACrE;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,cAAc;EACd,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".contact-us-bg {\n  background: linear-gradient(to right, #4b1507, #7b4c29);\n  padding-top: 35px;\n  padding-bottom: 25px;\n\n  color: #fff;\n}\n\n.contact-us-title {\n  color: #fff;\n}\n\n.contact-us-container {\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);\n  padding: 0px;\n  margin-top: 100px; /* Add this line to provide padding at the top */\n}\n\n.form-control {\n  border-radius: 5px;\n}\n\n.text-end .btn-success {\n  background-color: #28a745;\n  border-color: #28a745;\n}\n\n.text-end .btn-success:hover {\n  background-color: #218838;\n  border-color: #218838;\n}\n\n.social-media-links {\n  margin-top: 20px;\n}\n\n.social-media-links h5 {\n  color: #333;\n}\n\n.social-link {\n  display: inline-block;\n  margin: 0 10px;\n  color: #2e654c;\n  font-size: 1.2rem;\n  text-decoration: none;\n}\n\n.social-link:hover {\n  color: #feb47b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
