import React from 'react';
import PropTypes from 'prop-types';

const NonAuthLayout = ({ children }) => {
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.node // Ensures that children must be React nodes
};

export default NonAuthLayout;
