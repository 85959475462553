import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Input, Button, FormGroup, Card, Spinner, Alert } from "reactstrap";
import axios from "axios";
import profile from "assets/images/logo2.svg";

const Waitlist = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');  // New state for first name
  const [carType, setCarType] = useState('');  // New state for car type/information
  const [isLoading, setIsLoading] = useState(false);  // Manage loading state
  const [errorMessage, setErrorMessage] = useState('');  // Manage error state
  document.title = "Newsletter Signup | ADL";

  const handleSubmit = async () => {
    setIsLoading(true);  // Start loading
    setErrorMessage('');  // Clear any previous error messages
    try {
      // http://127.0.0.1:8080/api/newsletter
      // https://adl-website-423818.nn.r.appspot.com/api/newsletter
      const response = await axios.post('https://adl-website-423818.nn.r.appspot.com/api/newsletter', {
        email,
        phoneNumber,
        firstName,  // Include first name in the request
        carType     // Include car type/information in the request
      });
      console.log(response.data.message);
      setEmail('');
      setPhoneNumber('');
      setFirstName('');
      setCarType('');
      navigate('/newsletter-confirm');
    } catch (error) {
      console.error(error);
      setErrorMessage('There was an error submitting your information. Please try again.');
    } finally {
      setIsLoading(false);  // End loading
    }
  };

  const cardStyle = {
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '15px',
    marginTop: '2rem',
    padding: '2rem',
  };
  
  const inputStyle = {
    width: '100%',
    marginBottom: '1rem',
  };
  
  const buttonStyle = {
    width: '100%',
    padding: '12px',
    fontSize: '1rem',
    marginTop: '1rem',
  };

  const imageContainerStyle = {
    width: '100%',
    maxWidth: '500px',
    textAlign: 'center',
    margin: 'auto',
  };
  
  const imageStyle = {
    maxWidth: '100%',
    height: 'auto',
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Card style={cardStyle}>
                <Row className="no-gutters align-items-center">
                  <Col lg={7} className="p-4">
                    <h2>Welcome to Auto Detail Lab!</h2>
                    <p>Join our newsletter to receive the latest updates, exclusive offers, and car care tips directly in your inbox.</p>
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}  {/* Error alert */}
                    <FormGroup style={inputStyle}>
                      <Input 
                        placeholder="Enter first name" 
                        type="text" 
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup style={inputStyle}>
                      <Input 
                        placeholder="Enter email" 
                        type="email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup style={inputStyle}>
                      <Input 
                        placeholder="Enter phone number" 
                        type="tel" 
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup style={inputStyle}>
                      <Input 
                        placeholder="Enter car type/information" 
                        type="text" 
                        value={carType}
                        onChange={(e) => setCarType(e.target.value)}
                      />
                    </FormGroup>
                    <Button color="primary" style={buttonStyle} onClick={handleSubmit} disabled={isLoading}>
                      {isLoading ? <Spinner size="sm" /> : 'Subscribe Now!'}
                    </Button>
                  </Col>
                  <Col lg={5} className="text-center">
                    <div style={imageContainerStyle}>
                      <img src={profile} alt="ADL Logo" style={imageStyle} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Waitlist;
