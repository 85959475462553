import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import logo from "assets/images/fulllogo.jpg";

const ConfirmMail2 = () => {
  document.title = "Newsletter Signup Confirmed";
  const pageBackgroundColor = '#F9E5CA';


  const centerContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5rem',
    paddingBottom: '5rem',
    height: '30vh',
    pageBackgroundColor 
  };

  const centerContentStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '5rem',
    height: '30vh',
    pageBackgroundColor
  };



  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0}}>
        <div style={centerContentStyle}>
          <img
            src={logo}
            alt="ADL Logo"
            className="mb-0"
            style={{ maxWidth: '300px' }}
          />
        </div>
        <Container fluid>
          <Row>
            <Col>
              <div style={centerContentStyle2}>
                <h2 className="text-center">Thank You for Joining Our Newsletter!</h2>
                <p className="text-muted text-center">
                  We're excited to keep you updated with our latest offers, car care tips, and exclusive discounts!
                </p>
                <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                <Button color="primary" href="/detailing-home" className="mt-4">
                  Go to Homepage
                </Button>
              </div>
            </Col>
          </Row>
          <div className="mb-5"></div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfirmMail2;
