import React from "react";
import { Container, Row, Col } from "reactstrap";

// Import Components
import FeatureBox from "./feature-box";

// Import images
import detailingService1 from "../../../../assets/images/carwaxing.webp";
import detailingService2 from "../../../../assets/images/carsick.webp";

const Features = () => {
  const detailingFeatures1 = [
    { id: 1, desc: "Preserve your car's value with regular maintenance" },
    { id: 2, desc: "Prevent minor issues from becoming costly repairs" },
  ];
  const detailingFeatures2 = [
    { id: 1, desc: "Improve air quality inside your vehicle with our advanced ozone treatment" },
    { id: 2, desc: "Protect your health and well-being by eliminating harmful contaminants" },
  ];

  return (
    <React.Fragment>
      <section className="section" id="features">
        <Container>
          <Row>
            <Col lg="12">
            <div className="text-center mb-5">
                <div className="small-title" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Why Detailing Matters</div>
                <h4 style={{ fontSize: '2.0rem', fontWeight: 'bold' }}>Your Car, Your Health, Your Investment</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src={detailingService1}
                  alt="Car Waxing"
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              <FeatureBox
                num="01"
                title="Protect Your Investment"
                features={detailingFeatures1}
                desc={
                  <span>
                    <strong>Regular detailing is not just about aesthetics.</strong> It’s an investment in your car’s longevity. By keeping your vehicle clean, you’re <strong>protecting its resale value</strong> and avoiding potential damage from dirt, grime, and environmental contaminants.
                  </span>
                }
              />
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-md-5">
            <Col md="5">
              <FeatureBox
                num="02"
                title="Health Benefits"
                features={detailingFeatures2}
                desc={
                  <span>
                    <strong>Studies show that the air quality inside cars is often worse</strong> than in other places where we spend a lot of time. Our <strong>advanced ozone treatment</strong> can significantly improve the air you breathe inside your vehicle, <strong>eliminating harmful contaminants, allergens, and bacteria</strong>. Protect yourself and your passengers with regular interior detailing.
                  </span>
                }
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={detailingService2}
                  alt="Interior Detailing"
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
