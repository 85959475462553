// import React, { useState } from "react";
// import { Container, Row, Col, Badge } from "reactstrap";
// import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// import { blogs } from "./blogData";
// import logolight from "../../../../assets/images/logo-light.png";
// import './SwipeableImages.css'; // Import the new CSS file

// const Blog = () => {
//   return (
//     <React.Fragment>
//       <section className="section bg-white" id="news">
//         <Container>
//           <Row>
//             <Col lg="12">
//               <div className="text-center mb-5">
//                 <div className="small-title">Testimonials</div>
//                 <h4>Latest Reviews</h4>
//               </div>
//             </Col>
//           </Row>

//           <Row>
//             {blogs.map((blog, key) => (
//               <Col xl="4" sm="6" key={key}>
//                 <div className="blog-box mb-4 mb-xl-0">
//                   <div className="position-relative">
//                     <SwipeableImages images={blog.images} />
//                   </div>

//                   <div className="mt-4 text-muted">
//                     <h5 className="mb-3">{blog.title}</h5>
//                     <BlogDescription desc={blog.desc} />
//                   </div>
//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </Container>
//       </section>
//     </React.Fragment>
//   );
// };

// const BlogDescription = ({ desc }) => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const toggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <div>
//       <p>
//         {isExpanded ? desc : `${desc.substring(0, 100)}...`}
//       </p>
//       <button onClick={toggleExpand} className="btn btn-link p-0">
//         {isExpanded ? "Read less" : "Read more"}
//       </button>
//     </div>
//   );
// };

// const SwipeableImages = ({ images }) => {
//   console.log("images prop:", images);

//   if (!Array.isArray(images)) {
//     return <div>No images available</div>;
//   }

//   return (
//     <div className="swipeable-images-container position-relative">
//       <p className="text-center mt-2 mb-2">Swipe to see more images</p>
//       <Swiper
//         spaceBetween={50}
//         slidesPerView={1}
//         navigation
//         pagination={{ clickable: true }}
//         modules={[Navigation, Pagination, Scrollbar, A11y]}
//       >
//         {images.map((image, index) => (
//           <SwiperSlide key={index}>
//             <img
//               src={image}
//               alt={`Slide ${index}`}
//               className="rounded img-fixed-size mx-auto d-block"
//             />
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>
//   );
// };

// export default Blog;

import React from "react";
import { Container, Row, Col } from "reactstrap";
import InstagramFeed from "./instagramFeed";
import './SwipeableImages.css'; // Import the new CSS file

const Blog = () => {
  const accessToken = 'IGQWRPY3dtTnFUV3VVc1VjZAlhJMjNkZAVpmWmhLa09KYzlST0s2VVpaXzVsM0VpOVlmdUFmZA3lOcUx0YnotbDJqaFpiVlRQZAndWOHhWelhCUjNCekxEejVySVRKUzV6TzFBX0xjMUhiS0R0RmE1VUxDZAnY4cWRVN0EZD'; // Replace with your actual access token

  return (
    <React.Fragment>
      <section className="section bg-white" >
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="medium-title">Gallery</div>
                <h4>Latest Posts</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <InstagramFeed accessToken={accessToken} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Blog;

