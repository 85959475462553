import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Container, Row, Col, Card, CardBody, FormGroup, Label, Input, NavLink, NavItem,
  TabContent, TabPane, Form, Modal, ModalHeader, ModalBody, Button, Spinner
} from 'reactstrap';
import classnames from 'classnames';
import NavBar2 from './Nav';
import { useNavigate } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Footer from './Footer/footer';
import FullServicePage from './FullServicePage2';
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';

const BookAppointment = () => {
  document.title = "Booking Page | The Auto Detailing Lab";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);  // Manage loading state
  const [activeTabVertical, setActiveTabVertical] = useState(1);
  const [passedStepsVertical, setPassedStepsVertical] = useState([1]);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toggleTabVertical = (tab) => {
    if (!passedStepsVertical.includes(tab)) {
      setPassedStepsVertical([...passedStepsVertical, tab]);
    }
    if (activeTabVertical !== tab) {
      setActiveTabVertical(tab);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      serviceType: '',
      vehicleType: '',
      carBrand: '',
      vehicleLocation: '',
      interiorCondition: [],
      numberOfVehicles: 1,
      city: '',
      aptNo: '',
      electricalOutlet: '',
      waterSupply: '',
      spaceAvailability: '',
      date: new Date(),
      time: '',
      specialInstructions: '',
      streetAddress: '',
      postalCode: '',
      province: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      serviceType: Yup.string().required('Service type is required'),
      vehicleType: Yup.string().required('Vehicle type is required'),
      carBrand: Yup.string().required('Car brand is required'),
      vehicleLocation: Yup.string().required('Vehicle location is required'),
      city: Yup.string().required('City is required'),
      streetAddress: Yup.string().required('Street address is required'),
      postalCode: Yup.string().required('Postal code is required'),
      province: Yup.string().required('Province is required'),
      electricalOutlet: Yup.string().required('Electrical outlet availability is required'),
      waterSupply: Yup.string().required('Water supply availability is required'),
      spaceAvailability: Yup.string().required('Space availability is required'),
    }),
    //http://localhost:8080/api/bookings
    // https://adl-website-423818.nn.r.appspot.com/api/bookings
    onSubmit: async values => {

          setIsLoading(true); // Start loading

      try {
        await axios.post('https://adl-website-423818.nn.r.appspot.com/api/bookings', values);
        navigate('/page-confirm-mail');
      } catch (error) {
        console.error("Error submitting booking details:", error);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: values => {
      const errors = {};
      const errorTabMapping = {
        firstName: 1,
        lastName: 1,
        phone: 1,
        email: 1,
        waterSupply: 2,
        electricalOutlet: 2,
        spaceAvailability: 2,
        date: 2,
        time: 2,
        serviceType: 3,
        vehicleType: 3,
        carBrand: 3,
        vehicleLocation: 3,
        city: 4,
        streetAddress: 4,
        postalCode: 4,
        province: 4,
      };

      const validateField = (field, value, tab) => {
        if (!value) {
          errors[field] = `This field is required`;
          setIsLoading(false);
          if (!errors.tab) {
            errors.tab = tab;
          }
        }
      };

      Object.entries(values).forEach(([key, value]) => {
        if (errorTabMapping[key]) {
          validateField(key, value, errorTabMapping[key]);
        }
      });

      if (Object.keys(errors).length > 0 && errors.tab) {
        setActiveTabVertical(errors.tab);
      }

      return errors;
    },
  });

  const generateOptions = (options) => options.map((option, index) => (
    <option key={index} value={option.toLowerCase()}>{option}</option>
  ));

  return (
    <React.Fragment>
      <NavBar2 />
      <div className="page-content" style={{ paddingTop: '150px' }}>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Book Your Appointment</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem className={classnames({ current: activeTabVertical === 1 })}>
                          <NavLink className={classnames({ active: activeTabVertical === 1 })} onClick={() => toggleTabVertical(1)} disabled={!passedStepsVertical.includes(1)}>
                            <span className="number">1.</span> Customer Details
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTabVertical === 2 })}>
                          <NavLink className={classnames({ active: activeTabVertical === 2 })} onClick={() => toggleTabVertical(2)} disabled={!passedStepsVertical.includes(2)}>
                            <span className="number">2.</span> Logistics
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTabVertical === 3 })}>
                          <NavLink className={classnames({ active: activeTabVertical === 3 })} onClick={() => toggleTabVertical(3)} disabled={!passedStepsVertical.includes(3)}>
                            <span className="number">3.</span> Vehicle & Service Details
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTabVertical === 4 })}>
                          <NavLink className={classnames({ active: activeTabVertical === 4 })} onClick={() => toggleTabVertical(4)} disabled={!passedStepsVertical.includes(4)}>
                            <span className="number">4.</span> Client Address
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTabVertical} className="body">
                        <TabPane tabId={1}>
                          <Form onSubmit={formik.handleSubmit}>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="firstNameInput">First Name</Label>
                                  <Input type="text" className="form-control" id="firstNameInput" placeholder="Enter Your First Name" {...formik.getFieldProps('firstName')} />
                                  {formik.touched.firstName && formik.errors.firstName ? (<div className="text-danger">{formik.errors.firstName}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastNameInput">Last Name</Label>
                                  <Input type="text" className="form-control" id="lastNameInput" placeholder="Enter Your Last Name" {...formik.getFieldProps('lastName')} />
                                  {formik.touched.lastName && formik.errors.lastName ? (<div className="text-danger">{formik.errors.lastName}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phoneInput">Phone</Label>
                                  <Input type="text" className="form-control" id="phoneInput" placeholder="Enter Your Phone No." {...formik.getFieldProps('phone')} />
                                  {formik.touched.phone && formik.errors.phone ? (<div className="text-danger">{formik.errors.phone}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="emailInput">Email</Label>
                                  <Input type="email" className="form-control" id="emailInput" placeholder="Enter Your Email" {...formik.getFieldProps('email')} />
                                  {formik.touched.email && formik.errors.email ? (<div className="text-danger">{formik.errors.email}</div>) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="waterSupply">Is water supply available on-site within 50ft?</Label>
                                  <Input type="select" id="waterSupply" {...formik.getFieldProps('waterSupply')}>
                                    <option value="">Select Availability</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </Input>
                                  {formik.touched.waterSupply && formik.errors.waterSupply ? (<div className="text-danger">{formik.errors.waterSupply}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="electricalOutlet">Is there an electrical outlet within 50ft available on-site?</Label>
                                  <Input type="select" id="electricalOutlet" {...formik.getFieldProps('electricalOutlet')}>
                                    <option value="">Select Availability</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </Input>
                                  {formik.touched.electricalOutlet && formik.errors.electricalOutlet ? (<div className="text-danger">{formik.errors.electricalOutlet}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="spaceAvailability">Is adequate space available for detailing?</Label>
                                  <Input type="select" id="spaceAvailability" {...formik.getFieldProps('spaceAvailability')}>
                                    <option value="">Select Space Availability</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </Input>
                                  {formik.touched.spaceAvailability && formik.errors.spaceAvailability ? (<div className="text-danger">{formik.errors.spaceAvailability}</div>) : null}
                                  <small className="text-muted">
                                  Adequate space is a clear area around the vehicle, ideally with at least 3 feet of clearance.

                                </small>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>Date</Label>
                                  <Flatpickr
                                    className="form-control"
                                    value={formik.values.date}
                                    onChange={(date) => formik.setFieldValue('date', date[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d",
                                      minDate: "today"
                                    }}
                                  />
                                  {formik.touched.date && formik.errors.date ? (
                                    <div className="text-danger">{formik.errors.date}</div>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>Time</Label>
                                  <Input type="select" id="timeSelect" {...formik.getFieldProps('time')}>
                                    <option value="">Select Time Slot</option>
                                    <option value="6am - 9am">6am - 9am</option>
                                    <option value="10am - 1pm">10am - 1pm</option>
                                    <option value="2pm - 5pm">2pm - 5pm</option>
                                    <option value="6pm - 9pm">6pm - 9pm</option>
                                  </Input>
                                  {formik.touched.time && formik.errors.time ? (<div className="text-danger">{formik.errors.time}</div>) : null}
                                  <small className="text-muted">Note: The detailing service does not necessarily take 3 hours, but this is an estimated window of time for arrival and completion of the job.</small>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="specialInstructionsInput">Special Instructions </Label>
                                  <Input type="textarea" className="form-control" id="specialInstructionsInput" placeholder="Enter Special Instructions" {...formik.getFieldProps('specialInstructions')} />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="serviceTypeSelect">Service Type</Label>
                                  <Input type="select" id="serviceTypeSelect" {...formik.getFieldProps('serviceType')}>
                                    <option value="">Select Service Type</option>
                                    <option value="express">Express</option>
                                    <option value="comprehensive">Comprehensive</option>
                                    <option value="supreme">Supreme</option>
                                  </Input>
                                  {formik.touched.serviceType && formik.errors.serviceType ? (<div className="text-danger">{formik.errors.serviceType}</div>) : null}
                                  <Button color="link" onClick={toggleModal}>View Services</Button>
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="vehicleTypeSelect">Vehicle Type</Label>
                                  <Input type="select" id="vehicleTypeSelect" {...formik.getFieldProps('vehicleType')}>
                                    <option value="">Select Type</option>
                                    {generateOptions(['Sedan', 'Coupe', 'SUV', 'Minivan', 'Truck', 'Convertible'])}
                                  </Input>
                                  {formik.touched.vehicleType && formik.errors.vehicleType ? (<div className="text-danger">{formik.errors.vehicleType}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="carBrandSelect">Car Brand</Label>
                                  <Input type="select" id="carBrandSelect" {...formik.getFieldProps('carBrand')}>
                                    <option value="">Select Car Brand</option>
                                    {generateOptions(['Audi', 'BMW', 'Chevrolet', 'Ford', 'Honda', 'Mercedes', 'Tesla', 'Toyota', 'Volkswagen', 'Mazda', 'Other'])}
                                  </Input>
                                  {formik.touched.carBrand && formik.errors.carBrand ? (<div className="text-danger">{formik.errors.carBrand}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="vehicleLocationSelect">Vehicle Location</Label>
                                  <Input type="select" id="vehicleLocationSelect" {...formik.getFieldProps('vehicleLocation')}>
                                    <option value="">Select Vehicle Location</option>
                                    {generateOptions(['Driveway', 'House garage', 'Outdoor parking', 'Side street', 'Underground parking'])}
                                  </Input>
                                  {formik.touched.vehicleLocation && formik.errors.vehicleLocation ? (<div className="text-danger">{formik.errors.vehicleLocation}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="numberOfVehicles">Number of Vehicles</Label>
                                  <Input type="select" id="numberOfVehicles" {...formik.getFieldProps('numberOfVehicles')}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3+">3+</option>
                                  </Input>
                                  {formik.touched.numberOfVehicles && formik.errors.numberOfVehicles ? (<div className="text-danger">{formik.errors.numberOfVehicles}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>Describe the Interior Condition of the Car</Label>
                                  {['Minor pet hair', 'Major pet hair', 'Child car seat', 'Biowaste', '3 or more years since last detail'].map((condition, index) => (
                                    <FormGroup check key={index}>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          value={condition}
                                          checked={formik.values.interiorCondition.includes(condition)}
                                          onChange={(e) => {
                                            const set = new Set(formik.values.interiorCondition);
                                            if (e.target.checked) {
                                              set.add(e.target.value);
                                            } else {
                                              set.delete(e.target.value);
                                            }
                                            formik.setFieldValue('interiorCondition', Array.from(set));
                                          }}
                                        />
                                        {condition}
                                      </Label>
                                    </FormGroup>
                                  ))}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Form onSubmit={formik.handleSubmit}>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="citySelect">City</Label>
                                  <Input type="select" id="citySelect" {...formik.getFieldProps('city')}>
                                    <option value="">Select City</option>
                                    {generateOptions(['Ajax', 'Brampton', 'Burlington', 'Etobicoke', 'Hamilton', 'King City', 'Laval', 'Longueuil', 'Markham', 'Mississauga', 'Montreal', 'North York', 'Oakville', 'Oshawa', 'Pickering', 'Richmond Hill', 'Scarborough', 'Thornhill', 'Toronto', 'Vaughan', 'Whitby', 'Woodbridge'])}
                                  </Input>
                                  {formik.touched.city && formik.errors.city ? (<div className="text-danger">{formik.errors.city}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="provinceSelect">Province</Label>
                                  <Input type="select" id="provinceSelect" {...formik.getFieldProps('province')}>
                                    <option value="">Select Province</option>
                                    {generateOptions(['Ontario','Quebec'])}
                                  </Input>
                                  {formik.touched.province && formik.errors.province ? (<div className="text-danger">{formik.errors.province}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="postalCodeInput">Postal Code</Label>
                                  <Input type="text" className="form-control" id="postalCodeInput" placeholder="Enter Postal Code" {...formik.getFieldProps('postalCode')} />
                                  {formik.touched.postalCode && formik.errors.postalCode ? (<div className="text-danger">{formik.errors.postalCode}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="aptNoInput">Apt. No</Label>
                                  <Input type="text" className="form-control" id="aptNoInput" placeholder="Apt. Number" {...formik.getFieldProps('aptNo')} />
                                  {formik.touched.aptNo && formik.errors.aptNo ? (<div className="text-danger">{formik.errors.aptNo}</div>) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="streetAddressInput">Street Address</Label>
                                  <Input type="text" className="form-control" id="streetAddressInput" placeholder="Enter Street Address" {...formik.getFieldProps('streetAddress')} />
                                  {formik.touched.streetAddress && formik.errors.streetAddress ? (<div className="text-danger">{formik.errors.streetAddress}</div>) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li className={activeTabVertical === 1 ? "previous disabled" : "previous"}>
                          <Button onClick={() => toggleTabVertical(activeTabVertical - 1)} disabled={activeTabVertical === 1}>
                            Previous
                          </Button>
                        </li>
                        <li className={activeTabVertical === 4 ? "next submit" : "next"}>
                          <Button 
                            type="submit" 
                            color="primary" 
                            onClick={() => {
                              if (activeTabVertical === 4) {
                                setIsLoading(true);  
                                formik.handleSubmit();
                              } else {
                                toggleTabVertical(activeTabVertical + 1);
                              }
                            }}
                          >
                            {isLoading && activeTabVertical === 4 ? (
                              <span>
                                <Spinner size="sm" /> Submitting...
                              </span>
                            ) : (
                              activeTabVertical === 4 ? 'Submit' : 'Next'
                            )}
                          </Button>
                        </li>
                      </ul>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={modal} toggle={toggleModal} className="custom2-modal-size" style={{ maxWidth: '1500px', paddingTop: '30px' }}>
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <FullServicePage />
          </ModalBody>
        </Modal>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default BookAppointment;
