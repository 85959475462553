import React from "react"
import { Container, Row } from "reactstrap"

//Import Components
import CardBox from "./card-box"

const CardsMini = () => {
  const services = [
    {
      title: "Express Detailing",
      color: "warning",
      icon: "mdi mdi-car-wash",
      value: "From $120",
      description: "Ideal for regularly detailed cars, this package includes a thorough exterior wash, basic interior cleaning, and tire shine to keep your vehicle looking fresh.",
    },
    {
      title: "Comprehensive Detailing",
      color: "primary",
      icon: "mdi mdi-seat-recline-extra",
      value: "From $180",
      description: "Offers a detailed hand wash, wheel cleaning, deep interior cleaning, and more, perfect for those wanting a meticulous clean inside and out.",
    },
    {
      title: "Supreme Detailing",
      color: "info",
      icon: "mdi mdi-car-connected",
      value: "From $290",
      description: "Our ultimate package, including all services from the Express and Comprehensive packages, plus leather conditioning, ozone treatment, and wax application.",
    },
  ];
  

  return (
    <React.Fragment>
      <section className="section bg-white p-0">
        <Container>
          <div className="currency-price">
            <Row>
              {/* reder card boxes */}
              <CardBox services={services} />
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default CardsMini
