import React, { useState } from "react";
import { Container, Row, Col, Form, Label, Input, Button, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NavBar2 from "./Nav";
import Footer from "./Footer/footer";
import './ContactUs.css'; // Assuming you have a CSS file for additional styles

const ContactUs = () => {
  document.title = "Contact Us | The Auto Detailing Lab";

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [isLoading, setIsLoading] = useState(false); // Manage loading state

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      await axios.post('https://adl-website-423818.nn.r.appspot.com/api/contact', formData);
      navigate("/feedback-confirmation");
    } catch (error) {
      console.error("There was an error sending the message!", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <React.Fragment>
      <NavBar2 />
      <div className="contact-us-bg">
        <Container className="contact-us-container py-5">
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-5">
                <h2 className="contact-us-title">Contact Us</h2>
                <p className="text-muted">
                  We would love to hear from you! Fill out the form below to leave us a message or review.
                </p>
              </div>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="name">Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="mb-3">
                  <Label htmlFor="message">Message</Label>
                  <textarea
                    className="form-control"
                    id="message"
                    placeholder="Your message..."
                    rows="3"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="text-end">
                  <Button type="submit" color="success" className="w-sm">
                    {isLoading ? <Spinner size="sm" /> : 'Submit'}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ContactUs;
