// import React, { useState } from "react";
// import { Container, Row, Col, Card, CardBody } from "reactstrap";
// import { Link } from "react-router-dom";

// //Images
// import client1 from "../../../../assets/images/clients/1.png";
// import client2 from "../../../../assets/images/clients/2.png";
// import client3 from "../../../../assets/images/clients/3.png";
// import client4 from "../../../../assets/images/clients/4.png";
// import client5 from "../../../../assets/images/clients/5.png";
// import client6 from "../../../../assets/images/clients/6.png";

// //swiper
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "../../../../../node_modules/swiper/swiper.scss";

// const AboutUs = () => {

//   return (
//     <React.Fragment>
//       <section className="section pt-4 bg-white" id="about">
//         <Container>
//           <Row>
//             <Col lg="12">
//               <div className="text-center mb-5">
//                 <div className="small-title">About us</div>
//                 <h4>What is ICO Token?</h4>
//               </div>
//             </Col>
//           </Row>
//           <Row className="align-items-center">
//             <Col lg="5">
//               <div className="text-muted">
//                 <h4>Best ICO for your cryptocurrency business</h4>
//                 <p>
//                   If several languages coalesce, the grammar of the resulting
//                   that of the individual new common language will be more simple
//                   and regular than the existing.
//                 </p>
//                 <p className="mb-4">
//                   It would be necessary to have uniform pronunciation.
//                 </p>

//                 <div className="d-flex flex-wrap gap-2">
//                   <Link to="#" className="btn btn-success">
//                     Read More
//                   </Link>
//                   <Link to="#" className="btn btn-outline-primary">
//                     How It work
//                   </Link>
//                 </div>

//                 <Row className="mt-4">
//                   <Col lg="4" xs="6">
//                     <div className="mt-4">
//                       <h4>$ 6.2 M</h4>
//                       <p>Invest amount</p>
//                     </div>
//                   </Col>
//                   <Col lg="4" xs="6">
//                     <div className="mt-4">
//                       <h4>16245</h4>
//                       <p>Users</p>
//                     </div>
//                   </Col>
//                 </Row>
//               </div>
//             </Col>

//             <Col lg="6" className="ms-auto">
//               <div className="mt-4 mt-lg-0">
//                 <Row>
//                   <Col sm="6">
//                     <Card className="border">
//                       <CardBody>
//                         <div className="mb-3">
//                           <i className="mdi mdi-bitcoin h2 text-success" />
//                         </div>
//                         <h5>Lending</h5>
//                         <p className="text-muted mb-0">
//                           At vero eos et accusamus et iusto blanditiis
//                         </p>
//                       </CardBody>
//                       <div className="card-footer bg-transparent border-top text-center">
//                         <Link to="#" className="text-primary">
//                           Learn more
//                         </Link>
//                       </div>
//                     </Card>
//                   </Col>
//                   <Col sm="6">
//                     <Card className="border mt-lg-5">
//                       <CardBody>
//                         <div className="mb-3">
//                           <i className="mdi mdi-wallet-outline h2 text-success" />
//                         </div>
//                         <h5>Wallet</h5>
//                         <p className="text-muted mb-0">
//                           Quis autem vel eum iure reprehenderit
//                         </p>
//                       </CardBody>
//                       <div className="card-footer bg-transparent border-top text-center">
//                         <Link to="#" className="text-primary">
//                           Learn more
//                         </Link>
//                       </div>
//                     </Card>
//                   </Col>
//                 </Row>
//               </div>
//             </Col>
//           </Row>

//           <hr className="my-5" />
//           <Row>
//             <Col lg={12}>
//               <div className="hori-timeline">
//                 <Swiper
//                   slidesPerView={1}
//                   // spaceBetween={10}
//                   navigation
//                   pagination={{
//                     clickable: true,
//                   }}
//                   breakpoints={{
//                     678: {
//                       slidesPerView: 2,
//                     },
//                     992: {
//                       slidesPerView: 3,
//                     },
//                     1400: {
//                       slidesPerView: 4,
//                     }
//                   }}
//                   autoplay={{
//                     delay: 2500,
//                     disableOnInteraction: false,
//                   }}
//                   loop={true}
//                   modules={[Pagination, Navigation, Autoplay]}
//                   className="owl-carousel owl-theme clients-carousel" id="clients-carousel" dir="ltr">
//                   <SwiperSlide className="item">
//                     <div className="client-images">
//                       <img src={client1} alt="client-img" className="mx-auto img-fluid d-block" />
//                     </div>
//                   </SwiperSlide>
//                   <SwiperSlide className="item">
//                     <div className="client-images">
//                       <img src={client2} alt="client-img" className="mx-auto img-fluid d-block" />
//                     </div>
//                   </SwiperSlide>
//                   <SwiperSlide className="item">
//                     <div className="client-images">
//                       <img src={client3} alt="client-img" className="mx-auto img-fluid d-block" />
//                     </div>
//                   </SwiperSlide>
//                   <SwiperSlide className="item">
//                     <div className="client-images">
//                       <img src={client4} alt="client-img" className="mx-auto img-fluid d-block" />
//                     </div>
//                   </SwiperSlide>
//                   <SwiperSlide className="item">
//                     <div className="client-images">
//                       <img src={client5} alt="client-img" className="mx-auto img-fluid d-block" />
//                     </div>
//                   </SwiperSlide>
//                   <SwiperSlide className="item">
//                     <div className="client-images">
//                       <img src={client6} alt="client-img" className="mx-auto img-fluid d-block" />
//                     </div>
//                   </SwiperSlide>                  
//                 </Swiper>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </React.Fragment>
//   );
// };

// export default AboutUs;


import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Images
// import service1 from "../../../../assets/images/jobs.png";
// import service2 from "../../../../assets/images/jobs.png";
// import service3 from "../../../../assets/images/jobs.png";
// import testimonial1 from "../../../../assets/images/jobs.png";
// import testimonial2 from "../../../../assets/images/jobs.png";
// import testimonial3 from "../../../../assets/images/jobs.png";

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "../../../../../node_modules/swiper/swiper.scss";

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">About Us</div>
                <h4>Welcome to The Auto Detail Lab</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="text-muted">
                <h4>Expert Car Detailing Services</h4>
                <p>
                  At The Auto Detail Lab, we specialize in providing high-quality detailing services to make your vehicle look and feel new. Our team of professionals uses the latest techniques and products.
                </p>
                <p className="mb-4">
                  We focus on every detail to ensure your car receives the utmost care and attention.
                </p>

                <div className="d-flex flex-wrap gap-2">
                  <Link to="#" className="btn btn-success">
                    Learn More
                  </Link>
                  <Link to="/services" className="btn btn-outline-primary">
                    Our Services
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <Row>
                <Col sm="6">
                  <Card className="border">
                    <CardBody>
                      <div className="mb-3">
                        <i className="mdi mdi-car-wash h2 text-success" />
                      </div>
                      <h5>Quality Service</h5>
                      <p className="text-muted mb-0">
                        High-standard procedures and products for top-quality results.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card className="border mt-lg-5">
                    <CardBody>
                      <div className="mb-3">
                        <i className="mdi mdi-buffet h2 text-success" />
                      </div>
                      <h5>Comprehensive Cleaning</h5>
                      <p className="text-muted mb-0">
                        Thorough and detailed cleaning that rejuvenates your car’s appearance.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <hr className="my-5" />

          {/* <Row>
            <Col lg={12}>
              <div className="hori-timeline">
                <Swiper
                  slidesPerView={1}
                  navigation
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    678: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    }
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="owl-carousel owl-theme clients-carousel" id="clients-carousel" dir="ltr">
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img src={testimonial1} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img src={testimonial2} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img src={testimonial3} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide>                
                </Swiper>
              </div>
            </Col>
          </Row> */}
          </Container>
          </section>
     </React.Fragment>
   );
 };

 export default AboutUs;