import React from "react";
import { Container, Row, Col, Card, CardBody, Button, CardTitle, CardText, Table, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import NavBar2 from "./Nav";
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import './FullServicesTable.css';
import Footer from './Footer/footer';

const discount = 20; // Discount percentage

const servicePackages = [
  {
    tier: "Express Detail",
    services: [
      { vehicleType: "Large SUV", price: "$195" },
      { vehicleType: "Pickup Truck", price: "$165" },
      { vehicleType: "Regular SUV", price: "$155" },
      { vehicleType: "Car/Sedan", price: "$140" },
      { vehicleType: "2-3 Seat Car", price: "$120" },
    ],
    description: "Experience the comfort, bliss & longevity of driving a car that “feels like new”.",
    mobileVisitFee: "+ $65 Mobile Visit Fee",
    details: [
      "RECOMMENDED FOR CARS REGULARLY DETAILED",
      "Exterior Pre-Rinse",
      "Hand Wash and Rinse",
      "Clean Tires and Apply Tire Shine",
      "Basic Interior Vacuum (seats and floor)",
      "Wipe Down Dash and Console",
      "Clean Windows (inside and out)",
      "+ $45 Ozone Treatment (optional)"
    ]
  },
  {
    tier: "Comprehensive Detail",
    services: [
      { vehicleType: "Large SUV", price: "$255" },
      { vehicleType: "Pickup Truck", price: "$225" },
      { vehicleType: "Regular SUV", price: "$215" },
      { vehicleType: "Car/Sedan", price: "$200" },
      { vehicleType: "2-3 Seat Car", price: "$180" },
    ],
    description: "Add exterior foam bath and hand-wash of the body & rims, topped with a tire dressing for extra shine.",
    mobileVisitFee: "+ $65 Mobile Visit Fee",
    details: [
      "+ All EXPRESS SERVICES INCLUDED",
      "Foam Spray and Detailed Hand Wash (including logos and emblems)",
      "Deep Interior Vacuum (seats, floor mats, carpets, trunk)",
      "Clean Mats with All-Purpose Cleaner and Pressure Washer",
      "Drill Brush Seats and Steam Clean if Needed",
      "Wipe Down and Clean Gear Box, Cupholders, etc.",
      "Steam Clean Pedals",
      "Interior Detailing of Dashboard, Console, and Door Panels",
      "+ $45 Ozone Treatment (optional)"
    ]
  },
  {
    tier: "Supreme Detail",
    services: [
      { vehicleType: "Large SUV", price: "$365" },
      { vehicleType: "Pickup Truck", price: "$335" },
      { vehicleType: "Regular SUV", price: "$325" },
      { vehicleType: "Car/Sedan", price: "$310" },
      { vehicleType: "2-3 Seat Car", price: "$290" },
    ],
    description: "Ultimate car care with full interior and exterior cleaning, including leather treatment and odor elimination for a refreshing feel.",
    mobileVisitFee: "+ $65 Mobile Visit Fee",
    details: [
      "+ All EXPRESS & COMPREHENSIVE SERVICES INCLUDED",
      "High-Quality Wax or Synthetic Sealant Application with Buffing",
      "Steam Clean Entire Interior (seats, carpets, mats)",
      "Wet Vac Floors and Seats",
      "Steam Clean and Degrease Gear Box, Cupholders, etc.",
      "Detailed Cleaning of Interior Crevices and Vents",
      "Odor Removal/Disinfection Treatment (optional)",
      "Leather Conditioning (if applicable)",
      "Final Touches and Inspection",
      "Ozone Treatment"
    ]
  }
];

const FullServicesTable = () => {
  document.title = "Services | The Auto Detailing Lab";

  const [activeTab, setActiveTab] = React.useState('1');
  const navigate = useNavigate();

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleGetQuote = () => {
    navigate('/book-appointment');
  };

  const applyDiscount = (price, discount) => {
    const originalPrice = parseFloat(price.replace('$', ''));
    const discountPrice = (originalPrice * (1 - discount / 100));
    return { originalPrice: `$${originalPrice}`, discountPrice: `$${discountPrice.toFixed(2)}` };
  };

  return (
    <React.Fragment> 
      <Container fluid className="services-container mb-5 px-5">
        <NavBar2 />
        <Row className="justify-content-center" style={{ paddingTop: '150px' }}>
          <Col lg={10}>
            <div className="text-center mb-4 services-header">
              <h2 className="packages-title">Explore Packages</h2>
              <p className="text-muted">Select from our range of detailing services tailored for your vehicle.</p>
            </div>
          </Col>
          <Row className="package-cards-container">
            {servicePackages.map((packageItem, index) => (
              <Col key={index} lg={4} className="mb-4 package-card-col">
                <div className="service-package-wrapper">
                  <Card className="service-package-card custom-card">
                    <CardBody>
                      <CardTitle tag="h5" className="text-center card-title">{packageItem.tier}</CardTitle>
                      <Table bordered hover responsive className="custom-table">
                        <tbody>
                          {packageItem.services.map((service, serviceIndex) => {
                            const { originalPrice, discountPrice } = applyDiscount(service.price, discount);
                            return (
                              <tr key={serviceIndex}>
                                <td>{service.vehicleType}</td>
                                <td>
                                  <span className="original-price">{originalPrice}</span>
                                  <span className="discount-price">{discountPrice}</span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <CardText>{packageItem.description}</CardText>
                    </CardBody>
                  </Card>
                  <Nav tabs className="custom-tabs mt-3">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggleTab('1'); }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="custom-tab-content mb-4">
                    <TabPane tabId="1">
                      <div className="table-responsive">
                        <Table className="table table-light mb-0 ">
                          <tbody>
                            {packageItem.details.map((detail, detailIndex) => (
                              <tr key={detailIndex}>
                                <td style={{ fontWeight: detailIndex === 0 ? 'bold' : 'normal' }}>{detail}</td>
                                <td><i className="bx bxs-check-circle text-success icon-large"></i></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center mt-4">
            <Col lg={10} className="text-center">
              <Button className="w-lg" color="success" onClick={handleGetQuote}>Book Now </Button>
            </Col>
          </Row>
        </Row>
      </Container>
      <Footer/>
    </React.Fragment>
  );
}

export default FullServicesTable;
