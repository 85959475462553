import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import logo from "assets/images/adllogo2.svg"; // Ensure the correct path

const FeedbackConfirmMail = () => {
  document.title = "Feedback Submitted";

  const centerContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5rem',
    paddingBottom: '5rem',
    height: '30vh',
  };


  
  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <div style={centerContentStyle}>
          <img
            src={logo}
            alt="Auto Detail Lab Logo"
            className="mb-0"
            style={{ maxWidth: '300px' }}
          />
        </div>
        <Container fluid>
          <Row>
            <Col>
              <div style={centerContentStyle}>
                <h2>Thank You for Your Feedback!</h2>
                <p className="text-muted text-center">
                  We appreciate you taking the time to provide feedback. Your input helps us improve our services.
                </p>
                <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                <Button color="primary" href="/detailing-home" className="mt-4">
                  Go to Homepage
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FeedbackConfirmMail;
