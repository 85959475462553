import React from "react";
import { Container, Row, Col, Card, CardBody, Button, CardTitle, CardText, Table } from "reactstrap";
import NavBar2 from "./Nav";
import { useNavigate } from 'react-router-dom';

import './ServicesTable.css'; 

const discount = 20; // Discount percentage

const servicePackages = [
  {
    tier: "Express Detail",
    services: [
      { vehicleType: "Large SUV", price: "$195" },
      { vehicleType: "Pickup Truck", price: "$165" },
      { vehicleType: "Regular SUV", price: "$155" },
      { vehicleType: "Car/Sedan", price: "$140" },
      { vehicleType: "2-3 Seat Car", price: "$120" },
    ],
    description: "Experience the comfort, bliss & longevity of driving a car that “feels like new”.",
    mobileVisitFee: "+ $45 Ozone Treatment (optional)"
  },
  {
    tier: "Comprehensive Detail",
    services: [
      { vehicleType: "Large SUV", price: "$255" },
      { vehicleType: "Pickup Truck", price: "$225" },
      { vehicleType: "Regular SUV", price: "$215" },
      { vehicleType: "Car/Sedan", price: "$200" },
      { vehicleType: "2-3 Seat Car", price: "$180" },
    ],
    description: "Add exterior foam bath and hand-wash of the body & rims, topped with a tire dressing for extra shine.",
    mobileVisitFee: "+ $45 Ozone Treatment (optional)"
  },
  {
    tier: "Supreme Detail",
    services: [
      { vehicleType: "Large SUV", price: "$365" },
      { vehicleType: "Pickup Truck", price: "$335" },
      { vehicleType: "Regular SUV", price: "$325" },
      { vehicleType: "Car/Sedan", price: "$310" },
      { vehicleType: "2-3 Seat Car", price: "$290" },
    ],
    description: "Ultimate car care with full interior and exterior cleaning, including leather treatment and odor elimination for a refreshing feel.",
    mobileVisitFee: "Mobile Visit Fee Waived"
  },
];

const ServicesTable = () => {
  const navigate = useNavigate();
  const handleMoreInformation = () => {
    navigate('/full-service');
  };

  const applyDiscount = (price, discount) => {
    const originalPrice = parseFloat(price.replace('$', ''));
    const discountPrice = (originalPrice * (1 - discount / 100));
    return { originalPrice: `$${originalPrice}`, discountPrice: `$${discountPrice}` };
  };

  return (
    <Container className="mb-18 services-table-container">
      <NavBar2 />
      <Row className="justify-content-center">
        <Col lg={10}>
          <div className="text-center mb-4">
            <h2>Explore Packages</h2>
            <p className="text-muted">Select from our range of detailing services tailored for your vehicle.</p>
          </div>
        </Col>

        {servicePackages.map((packages, index) => (
          <Col lg={4} md={6} key={index}>
            <Card className="service-package-card mb-4 equal-card">
              <CardBody>
                <CardTitle tag="h5" className="text-center">{packages.tier}</CardTitle>
                <Table bordered hover responsive>
                  <tbody>
                    {packages.services.map((service, index) => {
                      const { originalPrice, discountPrice } = applyDiscount(service.price, discount);
                      return (
                        <tr key={index}>
                          <td>{service.vehicleType}</td>
                          <td>
                            <span className="original-price">{originalPrice}</span>
                            <span className="discount-price">{discountPrice}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardText>{packages.description}</CardText>
                <CardText>{packages.mobileVisitFee}</CardText>
                <div className="text-center">
                  <Button color="success" onClick={handleMoreInformation}>More Information</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ServicesTable;
