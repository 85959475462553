import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import illus from "assets/images/success_illus.png";
import logo from "assets/images/fulllogo_transparent_nobuffer.png";



const ConfirmMail = () => {
  document.title = "Reservation Confirmed";

  // Adjust styles to center the logo and content
  const centerContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5rem', // Added padding at the top
    // paddingBottom: '1rem', 
    height: '30vh', // This ensures that the content uses the full viewport height
  };
  const centerContentStyle2 = {
    display: 'flex',
    
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',
    paddingTop: '5rem', // Added padding at the top
    // paddingBottom: '5rem', // Added padding at the bottom
    height: '30vh', // This ensures that the content uses the full viewport height
  };
  const bottomImageStyle = {
    position: 'relative',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)', // Center the illustration

    maxWidth: '15%', // Ensures the image is responsive
    width: '100%', // Use the full width of the column
    height: 'auto', // Maintain the aspect ratio
    // height: 'var(--illus-height)', // Fixed height for the illustration
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
      <div style={centerContentStyle}>

      <img
                  src={logo} // Ensure this path is correct
                  alt="Paygoo Logo"
                  className="mb-0" // Adjust margin as needed
                  style={{ maxWidth: '300px' }}
                />
                </div>
        <Container fluid>
          <Row>
            <Col>
              <div style={centerContentStyle2}>
              {/* <img
                  src="/image/logo.png" // Ensure this path is correct
                  alt="Paygoo Logo"
                  className="mb-5" // Adjust margin as needed
                  style={{ maxWidth: '150px' }}
                /> */}
              <p className=" text-center items-center justify-center">

            <h2>Your Auto Detailing Appointment is Confirmed!</h2> </p>
                <p className="text-muted text-center items-center justify-center">
                Thank you for choosing us. We're excited to make your vehicle shine like new!
                </p>
                <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                <Button color="primary" href="/detailing-home" className="mt-4">
                Go to Homepage
                </Button>
              </div>
            </Col>
          </Row>
          <div className="mb-5"></div>
          {/* <Row>
            <Col> 
          <img
          src={illus} // Use the imported image path
          alt="Success Illustration"
          style={bottomImageStyle}
        />
        </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfirmMail;
