import React from "react";
import { Container, Row, Col, Card, CardBody, Button, CardTitle, CardText, Table } from "reactstrap";
import NavBar2 from "./Nav";
import Footer from "./Footer/footer";
import './SpecialOffers.css'; 

const discount = 20; // Discount percentage
const multiCarDiscount = 10; // Additional discount for multiple cars

const servicePackages = [

  {
    tier: "Express Detail",
    services: [
      { vehicleType: "Large SUV", price: "$195" },
      { vehicleType: "Pickup Truck", price: "$165" },
      { vehicleType: "Regular SUV", price: "$155" },
      { vehicleType: "Car/Sedan", price: "$140" },
      { vehicleType: "2-3 Seat Car", price: "$120" },
    ],
    description: "Experience the comfort, bliss & longevity of driving a car that “feels like new”.",
    mobileVisitFee: "Mobile Visit Fee Waived",
    details: [
      "Exterior Pre-Rinse",
      "Hand Wash and Rinse",
      "Dry Car",
      "Clean Tires and Apply Tire Shine",
      "Basic Interior Vacuum (seats and floor)",
      "Wipe Down Dash and Console",
      "Clean Windows (inside and out)"
    ]
  },
  {
    tier: "Comprehensive Detail",
    services: [
      { vehicleType: "Large SUV", price: "$255" },
      { vehicleType: "Pickup Truck", price: "$225" },
      { vehicleType: "Regular SUV", price: "$215" },
      { vehicleType: "Car/Sedan", price: "$200" },
      { vehicleType: "2-3 Seat Car", price: "$180" },
    ],
    description: "Add exterior foam bath and hand-wash of the body & rims, topped with a tire dressing for extra shine.",
    mobileVisitFee: "Mobile Visit Fee Waived",
    details: [
      "+ All EXPRESS SERVICES INCLUDED",
      "Full Wheel Cleaning and Tire Shine",
      "Foam Spray and Detailed Hand Wash (including logos and emblems)",
      "Wax Exterior or Apply Synthetic Sealant",
      "Deep Interior Vacuum (seats, floor mats, carpets, trunk)",
      "Clean Mats with All-Purpose Cleaner and Pressure Washer",
      "Drill Brush Seats and Steam Clean if Needed",
      "Clean and Condition Upholstery",
      "Wipe Down and Clean Gear Box, Cupholders, etc.",
      "Wipe Down Glove Box",
      "Steam Clean Pedals",
      "Use Interior Detailer for Dashboard, Console, and Door Panels"
    ]
  },
  {
    tier: "Supreme Detail",
    services: [
      { vehicleType: "Large SUV", price: "$365" },
      { vehicleType: "Pickup Truck", price: "$335" },
      { vehicleType: "Regular SUV", price: "$325" },
      { vehicleType: "Car/Sedan", price: "$310" },
      { vehicleType: "2-3 Seat Car", price: "$290" },
    ],
    description: "Ultimate car care with full interior and exterior cleaning for a refreshing feel.",
    mobileVisitFee: "Mobile Visit Fee Waived",
    details: [
      "+ All EXPRESS & COMPREHENSIVE SERVICES INCLUDED",
      "Clay Bar Treatment for Paint Decontamination",
      "High-Quality Wax or Synthetic Sealant Application with Buffing",
      "Steam Clean Entire Interior (seats, carpets, mats)",
      "Wet Vac Floors and Seats",
      "Steam Clean and Degrease Gear Box, Cupholders, etc.",
      "Detailed Cleaning of Interior Crevices and Vents",
      "Odor Removal Treatment (optional)",
      "Leather Conditioning (if applicable)",
      "Engine Bay Cleaning (optional)",
      "Final Touches and Inspection"
    ]
  }
];

const applyDiscount = (price, discount) => {
  const originalPrice = parseFloat(price.replace('$', ''));
  const discountPrice = (originalPrice * (1 - discount / 100)).toFixed(2);
  return { originalPrice: `$${originalPrice}`, discountPrice: `$${discountPrice}` };
};

const SpecialOffers = () => {
    document.title = "Offers | The Auto Detailing Lab";

  return (
    <React.Fragment>
      <div className="special-offers-bg">
        <Container fluid className="special-offers-container mb-5 px-5">
          <NavBar2 />
          <Row className="justify-content-center" style={{ paddingTop: '150px' }}>
            <Col lg={10}>
              <div className="text-center mb-4">
                <h2 className="special-offers-title">Special Offers</h2>
              </div>
            </Col>
            <Col lg={10} className="text-center">
              <div className="special-banner">
                <h3>Summer Special!</h3>
                <p>Additional <strong>10% off</strong> for any bookings for more than one car all summer long!</p>
                <p>Mobile Visit Fee <strong>Waived</strong></p>
                <p>Refer a friend and both you and your friend will receive <strong>$20 off</strong> your next detail!</p>
              </div>
            </Col>

            {servicePackages.map((packageItem, index) => (
              <Col lg={4} md={6} key={index} className="mb-4">
                <Card className="service-package-card vibrant-card">
                  <CardBody>
                    <CardTitle tag="h5" className="text-center">{packageItem.tier}</CardTitle>
                    <Table bordered hover responsive className="custom-table">
                      <tbody>
                        {packageItem.services.map((service, serviceIndex) => {
                          const { originalPrice, discountPrice } = applyDiscount(service.price, discount);
                          return (
                            <tr key={serviceIndex}>
                              <td>{service.vehicleType}</td>
                              <td>
                                <span className="original-price">{originalPrice}</span>
                                <span className="discount-price">{discountPrice}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <CardText>{packageItem.description}</CardText>
                    <CardText>{packageItem.mobileVisitFee}</CardText>
                    <div className="text-center">
                      {/* <Button color="warning">More Information</Button> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default SpecialOffers;
