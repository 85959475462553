import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./Section.css"; // Make sure to import the CSS file for custom styles

const Section = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen width and update state
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width threshold as needed
    };

    // Initial check
    checkScreenSize();

    // Add event listener to handle screen resizing
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <React.Fragment>
      <section className={isMobile ? "section hero-section bg-ico-hero-mobile" : "section hero-section bg-ico-hero"} id="home">
        <Container>
          <Row className="align-items-center">
            <Col lg={{ size: 5, order: 2 }} md={12} sm={12} className="text-center text-lg-end order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div className="text-white-50">
                <div className="background-overlay">
                  <h1 className="fw-bold mb-3 hero-title">REVITALIZE YOUR RIDE WITH ADL!</h1>
                </div>
                <div className="d-flex flex-wrap gap-2 mt-4 justify-content-center">
                  <Link to="/full-service" className="btn view-services-btn">View Services</Link>
                  <Link to="/book-appointment" className="btn book-appointment-btn">BOOK NOW!</Link>
                </div>
              </div>
            </Col>
            <Col lg={{ size: 7, order: 1 }} md={12} sm={12} className="mt-4 mt-lg-0 order-1 order-lg-1">
              <Card className="custom-card-overlay video-container-spacing desktop-video-position">
                <CardBody className="p-0">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      title="Introduction Video"
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/zUXhCDzkHy0?si=aB2NcDrpMf1IWIjA"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
