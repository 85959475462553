// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.book-appointment-btn {
  background-color: #32cd32 !important; /* Updated background color with !important */
  border: none !important;
  color: #ffffff !important;
  font-weight: bold !important;
  padding: 15px 30px !important;
  font-size: 18px !important; /* Adjust font size */
  transition: transform 0.3s, box-shadow 0.3s !important;
  display: inline-block; /* Ensure it stays inline */
}

.book-appointment-btn:hover {
  transform: scale(1.05) !important; /* Slightly reduce the scale */
  box-shadow: 0 0 15px rgba(228, 228, 228, 0.7) !important;
}

.view-services-btn {
  background-color: #c4c1c0 !important; /* Custom green background color */
  border: none !important;
  color: white !important;
  font-weight: bold !important;
  padding: 15px 30px !important;
  font-size: 18px !important; /* Adjust font size */
  transition: transform 0.3s, box-shadow 0.3s !important;
  display: inline-block; /* Ensure it stays inline */
}

.view-services-btn:hover {
  transform: scale(1.05) !important; /* Slightly reduce the scale */
  box-shadow: 0 0 15px rgba(40, 167, 69, 0.7) !important; /* Green shadow */
}

.d-flex .btn {
  margin: 0 5px; /* Add some margin to separate buttons */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Detailing/DetailingLanding/HeroSection/Section.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC,EAAE,6CAA6C;EACnF,uBAAuB;EACvB,yBAAyB;EACzB,4BAA4B;EAC5B,6BAA6B;EAC7B,0BAA0B,EAAE,qBAAqB;EACjD,sDAAsD;EACtD,qBAAqB,EAAE,2BAA2B;AACpD;;AAEA;EACE,iCAAiC,EAAE,8BAA8B;EACjE,wDAAwD;AAC1D;;AAEA;EACE,oCAAoC,EAAE,kCAAkC;EACxE,uBAAuB;EACvB,uBAAuB;EACvB,4BAA4B;EAC5B,6BAA6B;EAC7B,0BAA0B,EAAE,qBAAqB;EACjD,sDAAsD;EACtD,qBAAqB,EAAE,2BAA2B;AACpD;;AAEA;EACE,iCAAiC,EAAE,8BAA8B;EACjE,sDAAsD,EAAE,iBAAiB;AAC3E;;AAEA;EACE,aAAa,EAAE,wCAAwC;AACzD","sourcesContent":[".book-appointment-btn {\n  background-color: #32cd32 !important; /* Updated background color with !important */\n  border: none !important;\n  color: #ffffff !important;\n  font-weight: bold !important;\n  padding: 15px 30px !important;\n  font-size: 18px !important; /* Adjust font size */\n  transition: transform 0.3s, box-shadow 0.3s !important;\n  display: inline-block; /* Ensure it stays inline */\n}\n\n.book-appointment-btn:hover {\n  transform: scale(1.05) !important; /* Slightly reduce the scale */\n  box-shadow: 0 0 15px rgba(228, 228, 228, 0.7) !important;\n}\n\n.view-services-btn {\n  background-color: #c4c1c0 !important; /* Custom green background color */\n  border: none !important;\n  color: white !important;\n  font-weight: bold !important;\n  padding: 15px 30px !important;\n  font-size: 18px !important; /* Adjust font size */\n  transition: transform 0.3s, box-shadow 0.3s !important;\n  display: inline-block; /* Ensure it stays inline */\n}\n\n.view-services-btn:hover {\n  transform: scale(1.05) !important; /* Slightly reduce the scale */\n  box-shadow: 0 0 15px rgba(40, 167, 69, 0.7) !important; /* Green shadow */\n}\n\n.d-flex .btn {\n  margin: 0 5px; /* Add some margin to separate buttons */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
